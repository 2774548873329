<template>
  <v-container fluid>
    <CustomerEditForm/>
    <CustomersList/>
  </v-container>

</template>

<script>
import CustomersList from '@/components/ongoing/CustomersList';
import CustomerEditForm from '@/components/ongoing/CustomerAddForm';

export default {
  components: { CustomerEditForm, CustomersList },
  data() {
    return {
    };
  },
};
</script>

<style scoped>

</style>
