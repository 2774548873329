<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-text-field outlined readonly label="Id" :value="baseFields.externalId"/>
      </v-col>
      <v-col cols="8">
        <v-text-field outlined readonly label="Name" :value="baseFields.name"/>
      </v-col>
      <!--      <v-col cols="2">-->
      <!--        <v-text-field readonly label="Status" :value="baseFields.status"/>-->
      <!--      </v-col>-->
      <v-col v-if="baseFields.yearOfBirth && baseFields.yearOfBirth.length > 0">
        <v-text-field outlined readonly label="Year of birth" :value="baseFields.yearOfBirth"/>
      </v-col>
      <v-col v-if="baseFields.countries && baseFields.countries.length > 0">
        <v-text-field outlined readonly label="Countries" :value="baseFields.countries"/>
      </v-col>
      <v-col v-if="baseFields.dataSources && baseFields.dataSources.length > 0">
        <v-text-field outlined readonly label="Data sources" :value="baseFields.dataSources"/>
      </v-col>
      <v-col cols="3">
        <v-text-field outlined readonly label="Created" :value="baseFields.createdAt"/>
      </v-col>
      <v-col cols="3">
        <v-text-field outlined readonly label="Updated" :value="baseFields.updatedAt"/>
      </v-col>
      <v-col cols="8">
        <v-radio-group row outlined label="Status" v-model="status">
          <v-radio label="Created" value="created" readonly disabled/>
          <v-radio label="Valid" value="valid" readonly disabled/>
          <v-radio label="Alerted" value="alerted" readonly disabled/>
          <v-radio label="Verified" value="verified"/>
          <v-radio label="Flagged" value="flagged"/>
          <v-radio label="Escalated" value="escalated"/>
        </v-radio-group>
      </v-col>
      <v-row>
        <v-col>
          <v-btn color="secondary" @click="forceReset">Reset validation</v-btn>
        </v-col>
        <v-col>
          <v-btn color="secondary" @click="forceRefresh">Validate now</v-btn>
        </v-col>
      </v-row>
    </v-row>
    <RecordsList :value="value.records" v-if="value"/>
    <v-row>
      <v-btn color="primary" @click="save">Save changes</v-btn>
      <v-btn color="secondary">Cancel</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import RecordsList from '@/components/ongoing/RecordsList';

const { mapActions, mapMutations } = createNamespacedHelpers('customers');

export default {
  components: { RecordsList },
  props: {
    value: Object,
  },
  computed: {
    baseFields: (vm) => {
      const v = vm.$props.value;
      const {
        // eslint-disable-next-line camelcase
        externalId, name, status, yearOfBirth, countries, dataSources, createdAt, updatedAt,
      } = v;
        // const hasImages = v.information?.images?.length > 0;
        // eslint-disable-next-line camelcase
      return {
        externalId, name, status, yearOfBirth, countries, dataSources, createdAt, updatedAt,
      };
    },

  },
  data() {
    return {
      status: null,
    };
  },
  methods: {
    ...mapActions({
      updateCustomer: 'UPDATE_customer',
    }),
    ...mapMutations(
      { updateState: 'UPDATE_customer_SUCCESS' },
    ),
    async forceReset() {
      const response = await this.$ongoingClient.resetCustomer(this.value);
      this.updateState({ result: response.data });
      this.$emit('input', response.data);
    },
    async forceRefresh() {
      const response = await this.$ongoingClient.refreshCustomer(this.value);
      this.updateState({ result: response.data });
      this.$emit('input', response.data);
    },
    async save() {
      const updatedCustomer = await this.updateCustomer({
        externalId: this.value.externalId,
        status: this.status,
      });

      this.$emit('input', updatedCustomer);
    },
  },
  created() {
    this.status = this.value?.status;
  },
};
</script>

<style scoped>

</style>
