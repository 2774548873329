<template>
  <v-container>
    <v-row>
      <v-text-field
        label="Name"
        id="name"
        name="name"
        type="text"
        v-model.trim="searchQuery.name"
        :rules="[rules.required]"
        prepend-icon="person"
      />
    </v-row>
    <v-row>
      <v-text-field
        label="Tracking ID"
        id="trackingId"
        name="trackingId"
        type="text"
        v-model.trim="searchQuery.externalId"
        :rules="[rules.required]"
        prepend-icon="person"
      />
    </v-row>
    <v-row>
      <v-autocomplete label="Birth year"
                      :items="years"
                      v-model="searchQuery.yearsOfBirth"
                      multiple dense small-chips deletable-chips
      />
      <v-autocomplete label="Countries"
                      :items="countryList"
                      v-model="searchQuery.countries"
                      multiple dense small-chips deletable-chips
      />
      <v-autocomplete label="Data sources"
                      :items="dataSourceList"
                      v-model="searchQuery.dataSources"
                      multiple dense small-chips deletable-chips
      />
    </v-row>
    <v-row>
      <v-btn color="primary" @click="addCustomer">Add for tracking</v-btn>
    </v-row>

  </v-container>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, minLen } from '@/utils/validations';
import { lifeYearRange } from '@/utils/dates';

export default {
  computed: {
    ...mapGetters(['dataSourceList', 'countryList']),
    years: lifeYearRange,
  },
  data() {
    return {
      rules: {
        required,
        minLen,
      },
      searchQuery: {
        name: '',
        externalId: '',
        yearOfBirth: [],
        countries: [],
        dataSources: [],
      },
    };
  },
  methods: {
    ...mapActions({
      loadDataSources: 'READ_LIST_dataSource',
      loadCountries: 'READ_LIST_country',
      newCustomer: 'customers/CREATE_customer',
    }),
    async addCustomer() {
      await this.newCustomer(this.searchQuery);
    },
  },
  created() {
    this.loadCountries();
    this.loadDataSources();
  },
};
</script>

<style scoped>

</style>
