<template>
  <EntitiesList :items="value" :headers="headers" />
</template>

<script>
import EntitiesList from '@/components/entity/EntitiesList';

export default {
  components: { EntitiesList },
  props: { value: Array },
  data() {
    return {
      headers: [
        {
          text: 'Status',
          value: 'status',
          align: 'left',
          // width: '60%',
        },
        {
          text: 'Name',
          value: 'foundNames[0].name',
          align: 'left',
          // width: '60%',
        },
        {
          text: 'Id',
          value: 'uniqueEntityId',
          align: 'left',
          width: '10%',
        },
        {
          text: 'Type',
          value: 'entityType',
          align: 'left',
          // width: '10%',
        },
        {
          text: 'Category',
          value: 'category',
          align: 'left',
          // width: '10%',
        },
        {
          text: 'Source',
          value: 'sourceId',
          alight: 'left',
          // width: '10%',
        },
        {
          text: 'Score (%)',
          value: 'max_score',
          align: 'right',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
