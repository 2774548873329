<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Customers
            <div class="flex-grow-1"></div>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            />
            <div class="flex-grow-1"></div>
            <v-btn color="primary" @click="newCustomer">Create new</v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="externalId"
            fixed-header
            :sort-by="['createdAt']"
            :sort-desc="['true']"
            @click:row="viewCustomer"
          >
            <template v-slot:no-data>
              There are no tracked entities
            </template>
            <template v-slot:expanded-item="{item, headers}">
              <td :colspan="headers.length" class="pa-0">
                <v-col xl="10">
                  <EntitiesList :items="item.records" :headers="foundItemsHeaders"/>
                </v-col>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center"/>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import EntitiesList from '@/components/entity/EntitiesList';

const { mapActions, mapGetters } = createNamespacedHelpers('customers');

export default {
  components: {
    EntitiesList,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Id',
          value: 'externalId',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Year of birth',
          value: 'yearOfBirth',
        },
        {
          text: 'Countries',
          value: 'countries',
        },
        {
          text: 'Data sources',
          value: 'dataSources',
        },
      ],
      foundItemsHeaders: [
        {
          value: 'information.images',
          filter: (value) => (!this.onlyWithImages || (value && value.length)),
        },
        {
          text: 'Status',
          value: 'status',
          align: 'left',
          // width: '60%',
        },
        {
          text: 'Name',
          value: 'foundNames[0].name',
          align: 'left',
          // width: '60%',
        },
        {
          text: 'Id',
          value: 'uniqueEntityId',
          align: 'left',
          width: '10%',
        },
        {
          text: 'Type',
          value: 'entityType',
          align: 'left',
          // width: '10%',
        },
        {
          text: 'Category',
          value: 'category',
          align: 'left',
          // width: '10%',
        },
        {
          text: 'Source',
          value: 'sourceId',
          alight: 'left',
          // width: '10%',
        },
        {
          text: 'Score (%)',
          value: 'max_score',
          align: 'right',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ items: 'customerList' }),
  },
  created() {
    this.customerList();
  },
  methods: {
    ...mapActions({
      customerList: 'READ_LIST_customer',
    }),
    newCustomer() {
      this.$router.push('/ongoing/customers/new');
    },
    viewCustomer(row) {
      this.$router.push({ name: 'viewCustomer', filter: { value: row, id: row.externalId } });
    },
  },
};
</script>
