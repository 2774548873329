<template>
  <v-container fluid>
    <Customer :value="value"/>
<!--    <RecordsList :items="value.records"/>-->
  </v-container>
</template>

<script>
// import RecordsList from '@/components/ongoing/RecordsList';
import Customer from '@/components/ongoing/Customer';

export default {
  components: {
    Customer,
    // RecordsList,
  },
  props: {
    id: String,
    value: Object,
  },

};
</script>

<style scoped>

</style>
